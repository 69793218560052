import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../Component/Header";
import Footer from "../../Component/Footer";
import { useDispatch, useSelector } from "react-redux";
import {
  createVideoListRequest,
  emptyGenreData,
  fetchGenreListRequest,
  fetchMainmenuListRequest,
  fetchVideoListRequest,
  openRemoveWishlistRequest,
  openWishlistRequest,
  removeVideoListRequest,
  toggleToastBool,
} from "../../redux/actions/createListAction";
import { IRootState } from "../../redux/reducer";
import { ICreateListState } from "../../model/createList";
import WishListPopup from "../../Component/Popup/wishlistPopup";
import RemoveWishlistPopup from "../../Component/Popup/removeWishlistPopup";
import {
  setLeftSideMenuActive,
  setRightSideMenuActive,
} from "../../redux/actions/headerAction";
import { IHeaderinitialState } from "../../redux/reducer/headerReducer";
import Loader from "../../Component/Loader";
import {
  CONTENT_DETAIL,
  PLAN_PAGE,
  PLAYER,
  SIGN_IN,
} from "../../routes/routesConstant";
import dardroomSelected from "../../assets/img/darkroom-select.png";
import mostViewed from "../../assets/img/most-viewed.png";
import mostShared from "../../assets/img/most-shared.png";
import trendingImg from "../../assets/img/linked-img.png";
import LoginAlertPopup from "../../Component/Popup/loginAlertPopup";
import { MESSAGE } from "../../utils/constant";
import {
  continueWatchingEmpty,
  getContinueWatchingData,
} from "../../redux/actions/homeAction";
import { categorysearch } from "../Home/theme";

const Category = () => {
  const [searchParams] = useSearchParams();
  const [pageData, setPageData] = useState([]);
  const [count, setCount] = React.useState(0);
  const category_name: string | null = searchParams.get("category_name");
  const category_id: string | null = searchParams.get("category_id");
  const genre_type: string | null = searchParams.get("genre_type");
  const type: string | null = searchParams.get("type");
  const category_type: string | null = searchParams.get("category_type");
  const title: string | null = searchParams.get("title");
  const list_type: string | null = searchParams.get("list_type");
  const { isAddWishlistOpen, isRemoveWishlistOpen, video_id } = useSelector<
    IRootState,
    ICreateListState
  >((state) => state.createListReducer);
  const { subscriptionData } = useSelector<IRootState, any>(
    (state) => state.profileReducer,
  );
  const { left_active, right_active } = useSelector<
    IRootState,
    IHeaderinitialState
  >((state) => state.headerReducer);
  const [loginAlertBool, setLoginAlertBool] = React.useState("");

  const { dev_base_url, live_base_url } = useSelector<IRootState, any>(
    (state) => state.initialApiReducer,
  );
  const { loginData, logout } = useSelector<IRootState, any>(
    (state) => state.authReducer,
  );
  const { genreList, listCount } = useSelector<IRootState, ICreateListState>(
    (state) => state.createListReducer,
  );
  const { continueWatchingData } = useSelector<IRootState, any>(
    (state) => state.homeReducer,
  );
  const { watchListVideos, createVideoListBool, message } = useSelector<
    IRootState,
    ICreateListState
  >((state) => state.createListReducer);
  const { genre } = useSelector<IRootState, any>((state) => state.menuReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  if (category_type == "continuewatch" && !loginData?.user_id) {
    navigate("/signin");
  }
  useEffect(() => {
    if (genre.length > 0) {
      categorysearch();
    }
  }, [genre]);
  useEffect(() => {
    if (createVideoListBool) {
      toast(message);
      fetchCall(true);
      setCount(0);
    } else if (message) {
      toast(message);
    }
  }, [createVideoListBool, message]);
  useEffect(() => {
    dispatch(continueWatchingEmpty());
    window.scrollTo(0, 0);
    if (left_active || right_active) {
      dispatch(setLeftSideMenuActive(false));
      dispatch(setRightSideMenuActive(false));
    }
    return () => {
      dispatch(toggleToastBool());
      dispatch(emptyGenreData());
    };
  }, []);
  useEffect(() => {
    if (left_active || right_active) {
      dispatch(setLeftSideMenuActive(false));
      dispatch(setRightSideMenuActive(false));
    }
    setCount(0);
  }, [category_name]);
  useEffect(() => {
    setPageData([]);
    if (dev_base_url || live_base_url) {
      fetchCall(false);
    }
  }, [loginData, dev_base_url, live_base_url, category_name, logout]);
  useEffect(() => {
    if (dev_base_url || live_base_url) {
      fetchCall(false);
    }
  }, [count]);
  const fetchCall = (isReplace: boolean) => {
    if (
      category_name &&
      category_name
        .toLocaleLowerCase()
        .replace(" ", "")
        .includes("continuewatch")
    ) {
      dispatch(getContinueWatchingData(loginData?.user_id));
    } else if (type === "watch_list" || category_type) {
      dispatch(
        fetchVideoListRequest(loginData?.user_id, category_id, list_type),
      );
    } else if (type === "genre") {
      dispatch(
        fetchGenreListRequest(
          loginData?.user_id,
          category_id,
          genre_type,
          count,
          count === 0,
        ),
      );
    } else if (type === "main_menu") {
      dispatch(
        fetchMainmenuListRequest(
          loginData?.user_id,
          category_id,
          category_name,
          count,
          count === 0,
        ),
      );
    }
  };
  const getPaginationData = () => {
    setCount(count + 1);
  };
  const toggleRemoveWishlist = (val: boolean, video_id: number) => {
    dispatch(openRemoveWishlistRequest(val, video_id));
  };
  const toggleWishlist = (
    val: boolean,
    image: string | undefined,
    video_id: number,
  ) => {
    dispatch(openWishlistRequest(val, image, video_id));
    dispatch(toggleToastBool());
  };
  const saveVideoList = (watchListId: string) => {
    dispatch(createVideoListRequest(video_id, watchListId));
  };
  const removeVideoList = (video_id: number) => {
    dispatch(removeVideoListRequest(video_id));
  };
  useEffect(() => {
    if (genreList?.length) {
      setPageData(genreList);
    } else if (continueWatchingData?.[0]?.videos?.length) {
      setPageData(continueWatchingData?.[0]?.videos);
    } else {
      setPageData([]);
    }
  }, [genreList, continueWatchingData]);

  useEffect(() => {
    if (watchListVideos?.length) {
      setPageData(watchListVideos);
    } else {
      setPageData([]);
    }
  }, [watchListVideos]);

  const optionChange = (e: any, item: any) => {
    const category_id = item?.id;
    const category_name = item?.genre_name;
    navigate(
      `/category?category_id=${category_id}&category_name=${category_name}&type=genre&genre_type=0`,
    );
  };
  return (
    <>
      {loginAlertBool ? (
        <LoginAlertPopup
          setLoginAlertBool={setLoginAlertBool}
          loginAlertBool={loginAlertBool}
          message={loginAlertBool}
        />
      ) : (
        <></>
      )}
      {isAddWishlistOpen ? (
        <WishListPopup
          toggleWishlist={toggleWishlist}
          saveVideoList={saveVideoList}
        />
      ) : (
        <></>
      )}
      {isRemoveWishlistOpen ? (
        <RemoveWishlistPopup
          toggleRemoveWishlist={toggleRemoveWishlist}
          removeVideoList={removeVideoList}
        />
      ) : (
        <></>
      )}
      <Header />
      {category_id ? (
        <div className="category_page">
          <div className="category_main">
            {genre_type === "0" ? (
              <section className="categorysearch_section">
                <div className="container-fluid">
                  <div className="categorysearch_main">
                    <div className="categorysearch-slider owl-carousel">
                      {genre && genre.length > 0
                        ? genre.map((item: any) => {
                            return (
                              <div
                                className={`catsortbystatus_panel ${item.id == category_id ? "selected" : ""}`}
                                key={item.genre_name}
                                onClick={(e) => optionChange(e, item)}
                              >
                                <span className="catsortbystatus_text">
                                  {item.genre_name}{" "}
                                </span>
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                </div>
              </section>
            ) : (
              <section className="categorysearch_section">
                <div className="container-fluid">
                  <div className="categorysearch_main">
                    <div className="col-md-6 col-lg-7">
                      <div className="selectedrslts">
                        <strong>
                          {category_name} {title ? `- ${title}` : ""}
                        </strong>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            )}
            {pageData.length ? (
              <section className="category_section">
                <div className="container-fluid">
                  <div className="category_area">
                    <div className="category_grid">
                      <InfiniteScroll
                        style={{ height: "inherit", overflow: "hidden" }}
                        className="category-infinite"
                        dataLength={pageData.length}
                        next={() => {
                          if (category_type === "continuewatch") {
                          } else {
                            if (
                              pageData?.length <
                              (listCount ||
                                continueWatchingData?.[0]?.listCount)
                            ) {
                              getPaginationData();
                            }
                          }
                        }}
                        hasMore={true}
                        loader={
                          pageData?.length <
                          (listCount ||
                            continueWatchingData?.[0]?.listCount) ? (
                            <Loader type="portrate" />
                          ) : (
                            <></>
                          )
                        }
                      >
                        <div className="row">
                          {pageData?.map((item: any) => (
                            <div
                              className="col-6 col-sm-3 col-lg-2"
                              key={item.id}
                            >
                              <div className="movie-panel category_imgpanel">
                                <div className="movi-poster">
                                  <Link
                                    to={`${CONTENT_DETAIL}?video_id=${item.video_id}&video_type=${item.video_type}`}
                                    className="movieBox"
                                  >
                                    <img
                                      src={item["landscape_art_1280x720"]}
                                      alt=""
                                    />
                                  </Link>
                                  <div className="movishortinfo_panel">
                                    <span>
                                      <i
                                        className="fa fa fa-play"
                                        data-toggle="tooltip"
                                        data-placement="bottom"
                                        title="Play"
                                        onClick={() => {
                                          if (!loginData?.token) {
                                            // setLoginAlertBool(MESSAGE.CREATE_ACCOUNT_TO_PLAY_CONTENT)
                                            navigate(`${SIGN_IN}`);
                                          } else {
                                            if (
                                              subscriptionData?.subscription_status !=
                                                "Active" &&
                                              subscriptionData?.subscription_status !=
                                                "Cancelled" &&
                                              item.is_premium == "1"
                                            ) {
                                              navigate(`${PLAN_PAGE}`);
                                              return;
                                            }
                                            navigate(
                                              `${PLAYER}?video_id=${item.video_id}&video_type=${item.video_type}${item.seasion_id ? "&season_id=" + item.seasion_id : ""}${item.episode_id ? "&episode_id=" + item.episode_id : ""}`,
                                            );
                                          }
                                        }}
                                      ></i>
                                    </span>

                                    <span className="sesonepsd_text">
                                      {item.video_type === 2
                                        ? `S${item.seasion_number}E${item.episode_number}`
                                        : null}
                                    </span>
                                    {item.is_my_list ? (
                                      <span
                                        onClick={() => {
                                          toggleRemoveWishlist(
                                            true,
                                            item.video_id,
                                          );
                                        }}
                                      >
                                        <i
                                          className="fa fa-check"
                                          data-toggle="modal"
                                          data-target="#wishlist-popup"
                                          data-whatever="@wishlist-popup"
                                          data-placement="bottom"
                                          title="Remove from watchlist"
                                        ></i>
                                      </span>
                                    ) : (
                                      <span
                                        onClick={() => {
                                          if (!loginData?.token) {
                                            setLoginAlertBool(
                                              MESSAGE.LOGIN_TO_ADD_TO_WISHLIST,
                                            );
                                            return;
                                          }
                                          toggleWishlist(
                                            true,
                                            item.portrait_art_2000x3000,
                                            item.video_id,
                                          );
                                        }}
                                      >
                                        <i
                                          className="fa fa-plus"
                                          data-toggle="modal"
                                          data-target="#wishlist-popup"
                                          data-whatever="@wishlist-popup"
                                          data-placement="bottom"
                                          title="Add to watchlist"
                                        ></i>
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </InfiniteScroll>
                    </div>
                  </div>
                </div>
              </section>
            ) : (
              <div className="no-data"></div>
            )}
          </div>
        </div>
      ) : null}
      <div
        className="whiteOverlay2"
        onClick={() => dispatch(setLeftSideMenuActive(false))}
        style={{ display: left_active ? "block" : "" }}
      ></div>
      <div
        className="whiteOverlay3"
        onClick={() => dispatch(setRightSideMenuActive(false))}
        style={{ display: right_active ? "block" : "" }}
      ></div>

      <Footer />
    </>
  );
};

export default Category;
