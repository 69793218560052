import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "../../redux/reducer";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { post } from "../../api";
import { setRightSideMenuActive } from "../../redux/actions/headerAction";

const FriendsSharePopup = (props: any) => {
  const [email, setEmail] = useState("");
  const { listData, setShareFriendPopup } = props;
  const { loginData } = useSelector<IRootState, any>(
    (state) => state.authReducer,
  );
  const { dev_base_url, live_base_url } = useSelector<IRootState, any>(
    (state) => state.initialApiReducer,
  );
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {};
  }, []);

  const closepopup = () => {
    setShareFriendPopup(false);
  };

  const sendEmail = async () => {
    // console.log(email);
    if (email) {
      const response = await post(
        `/share-my-wishlist?user_id=${loginData?.user_id}&watchlist_id=${listData?.id}&to_email=${email}`,
      );
      if (response?.status_code) toast(response?.message);
      setShareFriendPopup(false);
      document.body.classList.remove("modal-open");
      window.$(".modal-backdrop").remove();
      dispatch(setRightSideMenuActive(false));
    }
  };

  return (
    <>
      <div className="share-popup sharefrndlist-popup">
        <div
          className="modal fade"
          id="sharefrndlist-popup"
          tabIndex={0}
          role="dialog"
          aria-labelledby="share-popupLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  closepopup();
                }}
              >
                <span aria-hidden="true">×</span>
              </button>
              <div className="modal-body">
                <div className="share-cont">
                  <div className="shareimgtitle_group">
                    <div className="sharefrndlistform_panel">
                      <div className="share_text">
                        <strong className="sharemovi-name">
                          My favorite {listData?.list_name}
                        </strong>
                      </div>
                      <div className="frndlist_form">
                        <form noValidate>
                          <div className="form-group">
                            <div className="input-group">
                              <input
                                type="email"
                                className="form-control text-center"
                                placeholder="Enter your friend email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                              <div className="input-group-append">
                                <button
                                  type="button"
                                  className="btn common-btn"
                                  onClick={() => {
                                    sendEmail();
                                  }}
                                >
                                  Send Email
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FriendsSharePopup;

